<template>
  <PreviewSpiner v-if="!ready"/>
  <div v-else>
    <div class="create-page" v-if="!steps.length">
      <CForm
        v-on:submit.prevent="createSingleStep"
        novalidate
        class="create-page__form"
      >
        <FormBuilder
          :list="formList"
          @updated="updateSettingsData"
          :page="{ title: texts.createPage.title, info: descriptions }"
        />
      </CForm>
    </div>

    <div class="create-page" v-else>
      <div class="create-page__header">
        <CRow class="mr-0 ml-0">
          <CCol col="12" sm="6" class="d-flex align-items-center pl-0">
            <div class="zq-page-title-wrapper d-flex">
              <h3 class="zq-page-title">{{ texts.createPage.title }}</h3>
              <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
            </div>
          </CCol>
          <CCol col="12" sm="6">
            <ActionCreateBtns
              :currentStep="currentStep"
              :totalStep="totalStep"
              :finishAction="createEntity"
              @updateCurrentStep="updateCurrentStep"
              @next-step="nextStep"
            />
          </CCol>
        </CRow>
        <WizardHeader
          :currentStep="currentStep"
          :steps="steps"
          @updateCurrentStep="updateCurrentStep"
        />
      </div>
      <div class="content">
        <CreateInstantWinSettings
          v-if="currentStep === 0"
          :list="formList"
          :descriptions="descriptions"
          :texts="texts"
          @updated="updateSettingsData"
        />
        <CelebrationMessages
          v-if="currentStep === 1"
          :messageSettings="messageSettings"
          @updateMessageSettings="updateMessageSettings"
          :texts="texts"
          :descriptions="descriptions"
        />
        <CreateInstantWinTiles
          ref="createTilesRef"
          v-if="steps.find(step => step.key === 'tiles') && currentStep === getStepNumber('tiles') + 1"
          :instantWinType="settingsData.instantWinType"
          :stepNumber="getStepNumber('tiles') + 1"
          :tilesData="instantWinTileData"
          @tile-updated="updateTilesData"
          @updateWheelSettings="updateWheelSettings"
          @updateTilesCount="updateTilesCount"
          :tilesCount.sync="tilesCount"
          :rows.sync="rows"
          :cols.sync="cols"
          :sectionsFilled="sectionsFilled"
          @createSectionStatusArray="createSectionStatusArray"
          :wheelSettings="wheelSettings"
          :messageSettings="messageSettings"
          :settingsData="settingsData"
          :model="model"
        />

        <CreateTranslations
          v-if="steps.find(step => step.key === 'translations') && currentStep === getStepNumber('translations') + 1"
          :entityData="settingsData"
          :translatableFields="translatableFields"
          :translationsData="translationsData"
          @updated="updateTranslationsData"
          :stepNumber="getStepNumber('translations') + 1"
        />
        <SummaryStep
          v-if="currentStep === getStepNumber('summary') + 1"
          :settingsData="settingsData"
          :rulesData="rulesData"
          :dependantOnData="steps.find(step => step.key === 'dependantOn') ? dependantOnData : {}"
          :schedulingData="steps.find(step => step.key === 'scheduling') ? schedulingData : {}"
          :rewardsData="rewardsData"
          :translationsData="translationsData"
          :stepNumber="getStepNumber('summary') + 1"
          :model="model"
          :translatableFields="translatableFields"
          :instantWinTileData="instantWinTileData"
          :instantWinType="instantWinType"
          :wheelSettings="wheelSettings"
          :messageSettings="messageSettings"
          :isVerticallyText="constraints.includes('isVerticallyText')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import ActionCreateBtns from '@/shared/components/steps/ActionCreateBtns';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import WizardHeader from '@/shared/components/steps/Header';
import CreateTranslations from '@/shared/components/supportModels/translations/CreateTranslations';
import CreateRewards from '@/shared/components/supportModels/rewards/AddRewards';
import SummaryStep from '@/shared/components/steps/SummaryStep';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { translationsTransform } from '@/utils/translationsUtils';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import { instantWins } from '@/config/descriptions/instantWins.json';
import { instantWinsTexts } from '@/config/pageTexts/instantWins.json';
import instantWinFields from '@/generated/ziqni/store/modules/instantWins/fields';
import { dateUTC } from '@/utils/dateUTC';
import { cloneDeep, delay } from 'lodash';
import PreviewSpiner from "@/shared/UI/Spiner";
import CreateInstantWinTiles from '@/shared/components/supportModels/instantWins/CreateInstantWinTiles.vue';
import Vue from 'vue';
import CreateInstantWinSettings from '@/views/ziqni/instant-wins/CreateInstantWinSettings';
import CelebrationMessages from '@/shared/components/supportModels/instantWins/CelebrationMessages.vue';


export default {
  name: 'CreateInstantWin',
  components: {
    CelebrationMessages,
    CreateInstantWinSettings,
    CreateInstantWinTiles,
    PreviewSpiner,
    ActionCreateBtns,
    IconWithTooltip,
    WizardHeader,
    CreateTranslations,
    CreateRewards,
    SummaryStep,
  },
  data() {
    return {
      model: 'instantWins',
      ready: true,
      currentStep: 0,
      totalStep: 0,
      descriptions: {
        ...instantWins.create,
      },
      texts: {
        ...instantWinsTexts,
      },
      steps: [],
      stepKeys: [],
      firstStep: {
        title: 'Settings',
        subTitle: stepSubTitles.settings,
        step: 0,
      },
      lastStep: {
        title: 'Summary',
        subTitle: stepSubTitles.summary,
        key: 'summary',
        step: 2,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
      dependantOnData: {
        currentTypes: {},
        formData: {
          shouldMatchAtLeast: null,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        },
        selectedData: []
      },
      dependantOnDataValidate: null,
      schedulingData: {
        scheduleOccurrencesLimit: null,
        scheduleType: '',
        constraints: [],
        endDate: '',
        startDate: dateUTC(),
        every: [],
      },
      schedulingDataValid_startDate: null,
      schedulingDataValid_every: null,
      schedulingDataValid_scheduleOccurrencesLimit: null,
      schedulingDataValid_scheduleType: null,
      rewardsData: [],
      transformedRewards: [],
      rulesData: {},
      instantWinTileData: [],
      tilesCount: 6,
      cols: 3,
      rows: 3,
      sectionsFilled: [],
      instantWinType: null,
      constraints: [],
      colorScale: ['#EE3EC8', '#7ED4E7'],
      wheelSettings: {
        buttonText: `<p><strong class="ql-font-arial" style="color: #F4B41C;">SPIN</strong></p>`,
        wheelBackground: '#5E084B',
        spinButtonBackground: '#5E084B',
        icon: '',
      },
      messageSettings: {
        celebrationMessage: '',
        celebrationText: '',
        celebrationImage: '',
        shapeOfCelebrationMessageBox: '',
        isCelebrationAnimation: false,

        sorryMessage: '',
        sorryText: '',
        sorryImage: '',
        shapeOfSorryMessageBox: '',
        isSorryAnimation: false
      }
    };
  },
  computed: {
    ...mapGetters('instantWins', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    instantWinType(newType, oldType) {
      if (newType !== oldType) {
        this.tilesCount = newType === 1 ? 6 : 9
        if (newType === 1) {
          const tilesData = []
          for (let i = 0; i < 6; i++) {
            tilesData.push({
              icon: '',
              text: '',
              // contraints: ['glow'],
              contraints: [],
              reward: {},
              location: {
                col: i + 1,
                row: 0
              },
              probability: null,
              autoProbability: true,
              background: this.colorScale[i % this.colorScale.length]
            })
          }
          this.instantWinTileData = [...tilesData]
        } else {
          const tilesData = []
          const cols = this.cols;
          const rows = this.rows;

          for (let row = 1; row <= rows; row++) {
            for (let col = 1; col <= cols; col++) {
              const index = (row - 1) * cols + col;
              tilesData.push({
                icon: '',
                text: '',
                // contraints: ['glow'],
                contraints: [],
                reward: {},
                location: {
                  col: col,
                  row: row
                },
                probability: null,
                autoProbability: true,
                background: '#fff'
              })
            }
          }
          this.instantWinTileData = [...tilesData]
        }
      }
    },
    tilesCount(newCount) {
      if (this.instantWinType !== 2) {
        const newTilesData = [];

        if (newCount > this.instantWinTileData.length && this.instantWinType !== 2) {
          newTilesData.push(...this.instantWinTileData)

          for (let i = this.instantWinTileData.length; i < newCount; i++) {
            newTilesData.push({
              icon: '',
              text: '',
              // contraints: ['glow'],
              contraints: [],
              reward: {},
              location: {
                col: i + 1,
                row: 0
              },
              probability: null,
              autoProbability: true,
              background: this.colorScale[i % this.colorScale.length]
            })
          }
        } else {
          newTilesData.push(...this.instantWinTileData.slice(0, newCount))
        }

        this.instantWinTileData = newTilesData;
      }
    },
    cols(newCols, oldCols) {
      if (newCols) {
        if (newCols > oldCols) {
          this.addCols(newCols, oldCols);
        } else if (newCols < oldCols) {
          this.removeCols(oldCols, newCols);
        }
      }
    },
    rows(newRows, oldRows) {
      if (newRows > oldRows) {
        this.addRows();
      } else if (newRows < oldRows) {
        this.removeRows(oldRows, newRows);
      }
    },
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('instantWins', ['handleCreateInstantWins',]),
    ...mapActions('files', ['handleSaveTemplates']),
    ...mapActions('fileRepositories', ['handleGetFileRepositories', 'handleGetFileRepositoriesByQuery']),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      this.formList = fieldHelpers.prepareCreateFormList(
        instantWinFields,
        instantWinsTexts.createPage,
        instantWins.create
      );

      let formSteps = [];

      this.formList.forEach((field, index) => {
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }
        if (field.key === 'statusCode') {
          this.formList.splice(index, 1)
        }
        if (field.required) {
          this.requiredFields.push(field.key);
          if (field.type === 'NAMEKEY') {
            this.requiredFields.push('name');
          }
        }
      })

      const nameIdx = this.formList.findIndex(f => f.key === 'name');

      if (nameIdx !== -1) {
        const instantWinTypeIdx = this.formList.findIndex(f => f.key === 'instantWinType');

        if (instantWinTypeIdx !== -1) {
          const instantWinType = this.formList.splice(instantWinTypeIdx , 1)[0];
          this.formList.splice(nameIdx , 0, instantWinType);
        }
      }

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type)
          if (step.key === 'scheduling') {
            this.requiredFields.scheduling = [];
            this.requiredFields.scheduling.push('scheduleType')
          }
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type)
        });

        this.steps.push(this.firstStep);
        let stepNumber = 1

        formSteps.forEach(step => {
          this.steps.push({
            title: step.label,
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: stepNumber,
          });
          this.stepKeys.push(step.key);

          stepNumber++;
        })

        const celebrationMessagesStep = {
          title: 'Celebration Messages',
          subTitle: 'Celebration Messages',
          key: 'celebration-messages',
          step: 1,
        }

        const tilesIndex = this.steps.findIndex(step => step.key === 'tiles');
        if (tilesIndex !== -1) {
          this.steps.splice(tilesIndex, 0, celebrationMessagesStep);
        }

        this.translatableFields = ['name', 'description', 'termsAndConditions'];

        this.lastStep.step = stepNumber;
        this.steps.push(this.lastStep);

        this.totalStep = formSteps.length + 2;
      }
    },
    addCols(newCols, oldCols) {
      const tilesPerColumn = this.rows

      const newTiles = []
      for (let j = 0; j < tilesPerColumn; j++) {
        newTiles.push({
          icon: '',
          text: '',
          // contraints: ['glow'],
          contraints: [],
          reward: {},
          location: {
            col: Number(newCols),
            row: j + 1,
          },
          probability: null,
          autoProbability: true,
          background: '#fff'
        });

        const insertIndex = (j + 1) * tilesPerColumn + j;

        this.instantWinTileData.splice(insertIndex, 0, newTiles.find(item => item.location.row === j + 1))
      }
      this.recalculateLocation();
    },
    removeCols(oldCols, newCols) {
      for (let j = 0; j < this.rows; j++) {
        const index = this.instantWinTileData.findIndex(item => item.location.col === +oldCols && item.location.row === j + 1)
        this.instantWinTileData.splice(index, 1)
      }
      this.recalculateLocation();
    },
    addRows() {
      for (let j = 0; j < this.cols; j++) {
        this.instantWinTileData.push({
          icon: '',
          text: '',
          // contraints: ['glow'],
          contraints: [],
          reward: {},
          location: {
            col: j + 1,
            row: Number(this.rows),
          },
          probability: null,
          autoProbability: true,
          background: '#fff'
        });
      }
      this.recalculateLocation();
    },
    removeRows(oldRows, newRows) {
      const index = this.instantWinTileData.length + 1 - Number(oldRows)
      this.instantWinTileData.splice(index, +this.cols);
      this.recalculateLocation();
    },
    recalculateLocation(){
      this.instantWinTileData.forEach((tile, index) => {
        tile.location.col = (index % this.cols) + 1
        tile.location.row = Math.floor(index / this.cols) + 1
      })
    },
    getSectionStatus(tile) {
      const isFullyFilled = tile.icon && tile.text && tile.probability !== null

      const isPartialFilled = tile.icon || tile.text || tile.probability === null

      if (isFullyFilled) {
        return 'filled'
      } else if (isPartialFilled) {
        return 'partialFilled'
      } else {
        return 'default'
      }
    },
    createSectionStatusArray() {
      const sectionsStatus = []
      this.instantWinTileData.forEach((tile, index) => {
        const status = this.getSectionStatus(tile)
        sectionsStatus.push({section: index + 1, status})
      })
      this.sectionsFilled = sectionsStatus
    },
    updateTilesCount(newTilesCount) {
      this.tilesCount = newTilesCount
    },
    updateCurrentStep(val) {
      this.currentStep = val;
    },
    nextStep() {
      let invalidFields = this.getInvalidFields(true);
      if (!invalidFields.length) {
        this.currentStep += 1;
      } else {
        this.setInvalidFields(invalidFields);
      }
    },
    getInvalidFields() {
      let result = [];
      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }
      if (this.getStepNumber('scheduling') === this.currentStep) {
        for (let key in this.schedulingData) {
          if (this.requiredFields.scheduling.includes(key) && (this.schedulingData[key] === null || this.schedulingData[key] === '')) {
            result.push(key);
          }
        }
      }

      return result;
    },
    getStepNumber(key) {
      let entityObject = this.steps.find(step => step.key === key);
      if (entityObject !== undefined && entityObject.hasOwnProperty('step')) {
        return entityObject.step;
      } else {
        return -1;
      }
    },
    updateSettingsData(val) {
      if (val.instantWinType) {
        this.instantWinType = val.instantWinType
      } else {
        this.instantWinType = 1
      }

      this.settingsData = val;

      if (!this.settingsData.instantWinType) this.settingsData.instantWinType = 1
    },
    updateDependantOnData(val) {
      this.dependantOnData = val;
    },
    resetDependantValidation() {
      this.dependantOnDataValidate = null;
    },
    updateDependantOnCurrentTypes(val) {
      this.dependantOnData.currentTypes = val;
    },
    updateSchedulingData(val) {
      this.schedulingData = val;
    },
    resetSchedulingValidate() {
      this.schedulingDataValid_startDate = null;
      this.schedulingDataValid_every = null;
      this.schedulingDataValid_scheduleOccurrencesLimit = null;
      this.schedulingDataValid_scheduleType = null;
    },
    updateTranslationsData(obj) {
      this.translationsMap = obj.map;
      this.translationsData = obj.val;
    },
    updateRewardData(rewards) {
      this.rewardsData = rewards;
      let localRewards = cloneDeep(rewards);
      let transformedRewards = [];
      if (localRewards.length) {
        localRewards.forEach(reward => {
          reward.rewardTypeId = reward.rewardType.id;
          delete reward.rewardType;
          delete reward.id;
          delete reward.spaceName;
          delete reward.created;
          delete reward.entityType;
          delete reward.entityId;
          delete reward.translatableFields;
          transformedRewards.push(reward);
        })
      }
      this.transformedRewards = transformedRewards;
    },
    updateRulesData(val) {
      this.rulesData = val;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    getRules() {
      let rules = [];
      for (const action in this.rulesData) {
        let ruleObject = {};

        ruleObject.entityId = '';
        ruleObject.action = action;
        ruleObject.context = 'instantWin';
        ruleObject.rules = this.rulesData[action];
        ruleObject.rules.type = 'condition';
        ruleObject.rules.lineNumber = 1;

        rules.push(ruleObject);
      }

      return rules
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    updateTilesData(payload) {
      const { index, updatedTile, changedProperty } = payload;

      const reactiveTile = Vue.observable(updatedTile);

      if (changedProperty === 'reward') {
        this.instantWinTileData[index].reward = reactiveTile.reward;

        const contraints = [...reactiveTile.contraints];

        if (Object.keys(reactiveTile.reward).length > 0) {
          const isHasPrizes = reactiveTile.contraints.includes('hasPrizes');
          if (!isHasPrizes) {
            contraints.push('hasPrizes');
          }
        } else {
          const index = reactiveTile.contraints.indexOf('hasPrizes');

          if (index !== -1) {
            contraints.splice(index, 1);
          }
        }

        this.instantWinTileData[index].contraints = contraints;
      }

      this.$set(this.instantWinTileData, index, reactiveTile);

      this.$refs.createTilesRef.updateChartsData();
    },
    updateWheelSettings(payload) {
      this.wheelSettings = payload;
    },
    updateMessageSettings(payload) {
      this.messageSettings = payload;
    },
    async createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        formData = {...this.settingsData};
      }
      if (Object.keys(this.translationsData).length) {
        formData.translations = translationsTransform(this.translationsData, this.translationsMap);
      }
      if (this.transformedRewards.length) {
        formData.rewards = this.transformedRewards;
      }
      if (Object.keys(this.schedulingData).length && this.schedulingData.scheduleType) {
        formData.scheduling = this.schedulingData;
      }
      if (Object.keys(this.rulesData).length) {
        formData.rules = this.getRules();
      }
      if (Object.keys(this.dependantOnData).length && this.dependantOnData.formData) {
        let isDependantOn = false;
        for (let key in this.dependantOnData.formData.dependantOn) {
          if (this.dependantOnData.formData.dependantOn[key].length) {
            isDependantOn = true;
          }
        }
        if (isDependantOn) {
          formData.dependantOn = {};
          formData.dependantOn.dependantOn = this.dependantOnData.formData.dependantOn;
          formData.dependantOn.entityType = 'member';
        }
      }

      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }

      this.instantWinTileData.forEach(tile => {
        delete tile.reward.rewardType;
        delete tile.reward.id;
        delete tile.reward.spaceName;
        delete tile.reward.created;
        delete tile.reward.entityType;
        delete tile.reward.entityId;
        delete tile.reward.translatableFields;
        delete tile.autoProbability;

        if (this.instantWinType === 1 && Object.keys(tile.reward).length > 0) {
          tile.reward.rewardRank = '0';
        }

      });

      formData.tiles = this.instantWinTileData;
      formData.constraints = this.constraints;

      const body = [];
      body.push(JSON.parse(JSON.stringify(formData)));

      this.ready = false;

      this.handleCreateInstantWins({createInstantWinRequestArray: body})
        .then(async data => {
          if (data.length) {

            const repository = await this.handleGetFileRepositoriesByQuery({
              queryRequest: {
                must: [{
                  queryField: 'name',
                  queryValues: ['system-resources'],
                }]
              }
            });

            const template = {
              wheelSettings: this.wheelSettings,
              messageSettings: this.messageSettings
            };

            const templateString = JSON.stringify(template);
            const folderName = '/instant-wins';

            const payload = {
              tagsArray: 'instantWins',
              parentFolderPath: folderName,
              repositoryId: repository[0].id,
              templateToSave: templateString,
              templateName: data[0].id,
            };

            await this.handleSaveTemplates(payload);

            delay(() => {
              this.$router.push({
                name: 'PreviewInstantWin',
                params: {
                  id: data[0].id,
                }
              })
            }, 5000);
          } else {
            this.ready = true;
            console.log('Something went wrong');
          }
        })
        .catch(error => {
          this.ready = true;
          console.error(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
